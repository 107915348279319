export const linksOne = [
  {
    id: "l1",
    title: "Media",
  },
  {
    id: "l2",
    title: "Community",
  },
  {
    id: "l3",
    title: "Terms & condition",
  },
  {
    id: "l4",
    title: "policy",
  },
];
export const linksTwo = [
  {
    id: "l5",
    title: "About",
  },
  {
    id: "l6",
    title: "Careers",
  },
  {
    id: "l7",
    title: "Get in touch",
  },
];
