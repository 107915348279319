import React, { useState, useEffect } from "react";
import { Container } from "ui";
import Section from "./styles";
import { Conference } from "assets/images";

const Hero = () => {

  const names = ["Barack Obama", "Steve Jobs", "Gary Vee", "Donald Trump", "Tony Robbins", "Sadhguru"];
  const [currentName, setCurrentName] = useState(names[0]);

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % names.length;
      setCurrentName(names[currentIndex]);
    }, 5000); // Change the name every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Section>
      <Container>
        <div className="hero--text">
          <p data-aos="fade-up" data-aos-duration="1000">
            {/* Unlock Your Inner {currentName}: */}
            Suprise Everyone:
          </p>
          <p data-aos="fade-up" data-aos-duration="850" data-aos-delay="1000">
          {/* Practice Your Upcoming Presentation in Front of the <span> Safest </span> */}
          Practice Public Speaking Privately in Front of the <span> Safest </span>
          </p>
          {/* <p data-aos="fade-up" data-aos-duration="1100" data-aos-delay="1000">
          in Front of the <span> Safest </span>
          </p> */}
          <p data-aos="fade-up" data-aos-duration="1300" data-aos-delay="1700">
            Most Helpful Human Audience Online.
          </p>
        </div>
        <div className="hero--shadow">
          <div
            className="hero--image"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="2000"
          >
            <img src={Conference} alt="Conference" loading="lazy" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Hero;
