import { breakPoint } from "base";
import { mediumScreen } from "helpers";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  background-color: var(--black);
  color: var(--white-grey);
  font-size: clamp(0.875rem, 0.6181rem + 0.8222vw, 0.9rem);
  padding: clamp(1.875rem, 1.8181rem + 0.1822vw, 1.9775rem) 0;

  .footer--content {
    display: flex;
    gap: clamp(2.5rem, -7.8045rem + 32.9744vw, 21.048125rem);

    ${mediumScreen(breakPoint.sm)} {
      flex-direction: column;

      p {
        width: 100%;
      }
    }

    p {
      width: 274px;
      max-width: 100%;
      line-height: 170%;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: clamp(0.8125rem, 0.5417rem + 0.8667vw, 1.3rem);
      a {
        color: var(--white-grey);
      }
    }
  }

  p.rights {
    color: var(--white-grey);
    font-feature-settings: "salt" on, "liga" off;
    font-size: 0.7766rem;
    line-height: 171.429%;
    padding-top: clamp(3.5rem, -2.6875rem + 13vw, 8.6875rem);
  }
`;

export default FooterWrapper;
