import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

:root {
  --black: #000;
  --white: #fff;
  --silver: #0D995E;
  --white-grey: #D9DBE1;
  --primary: #690BE1;
  --primary-grey: #6F6C90;
  --neutral: #170F49;
  

}


   html,
   html *,
   html *::before,
   html *::after {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
   }
   html {
      scroll-behavior: smooth;
   }
   body {
   
      ${({ theme }) => theme.fonts.lexend};
      font-weight: 400;
   }

   ul {
      list-style: none;
   }
  
   a {
      text-decoration: none;
      
   }



   img {
      max-width: 100%;
   }

	

	 /* Helper Classes */
	 .flex {
		display: flex;
		align-items: center;
	 }

	 .flex-sb {
		display: flex;
		align-items: center;
		justify-content: space-between;
	 }
  
	
`;

export default GlobalStyles;
