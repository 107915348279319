import { Link } from "react-router-dom";
import { PiCaretUpBold, PiCaretDownBold } from "react-icons/pi";
import { FiArrowUpRight } from "react-icons/fi";
import Card from "./styles";

const FaqCard = ({ faq, onToggle, isActive, onGetStarted }) => {

  const handleGetStarted = (e) => {
    e.preventDefault();
    onGetStarted();
  };

  return (
    <Card $isActive={isActive}>
      <div className="question" onClick={onToggle}>
        <p>{faq.question}</p>
        <button >
          {isActive ? (
            <PiCaretUpBold className="up" />
          ) : (
            <PiCaretDownBold className="down" />
          )}
        </button>
      </div>

      <div className={`answer ${isActive ? "active" : ""}`}>
        <p>{faq.answer}</p>

        <div className="link">
          {/* <Link to="#">
            <span>{faq.link}</span>
            <FiArrowUpRight />
          </Link> */}
          <a href="#" onClick={handleGetStarted}>
            <span>{faq.link}</span>
            <FiArrowUpRight />
          </a>
        </div>
        <div className="faq--img">
          <img src={faq?.image} alt="faq_img" loading="lazy" />
        </div>
      </div>
    </Card>
  );
};

export default FaqCard;
