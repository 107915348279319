import { breakPoint } from "base";
import { mediumScreen } from "helpers";
import styled from "styled-components";

const Header = styled.header`
  padding: clamp(1.0625rem, 0.5069rem + 1.7778vw, 2.0625rem) 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  nav {
    h6 {
      ${({ theme }) => theme.mixins.text_28};
    }
    ul {
      margin-left: auto;

      li {
        margin-left: 3.2rem;

        & > a {
          ${({ theme }) => theme.mixins.text_20};

          &.link {
            ${({ theme }) => theme.mixins.text_dark_light};
            letter-spacing: 2.5px;

            ${mediumScreen(breakPoint.sm)} {
              display: none;
            }
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child > a {
          padding: 0.8rem clamp(2.0625rem, 0.2569rem + 5.7778vw, 4.5rem);
          background-color: var(--primary);
          color: var(--white);
          font-weight: 600;
          border-radius: 105.254px;
        }
      }
    }
  }
`;

export default Header;
