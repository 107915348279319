import { Link } from "react-router-dom";

const FooterLink = ({ links }) => {
  return (
    <ul>
      {links.map((link) => (
        <li key={link.id}>
          <Link to="#">{link.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterLink;
