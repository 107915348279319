import { breakPoint } from "base";
import { mediumScreen } from "helpers";
import styled, { css, keyframes } from "styled-components";

const slideDown = keyframes`
0% {
  transform: translateY(-20px);
} 100% {
  transform: translateY(0);
  
}


`;

const Card = styled.div`
  padding-left: clamp(1.3rem, 1.0417rem + 2.6667vw, 2rem);
  border-left: ${({ $isActive }) =>
    $isActive ? `2px solid var(--primary)` : `2px solid rgba(0, 0, 0, 0.2)`};

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    padding-top: clamp(1.3rem, 1.0417rem + 2.6667vw, 1.8rem);
  }

  &:nth-child(1),
  &:nth-child(2) {
    padding-bottom: clamp(1.1rem, 1.0417rem + 2.6667vw, 1.4rem);

    ${({ $isActive }) =>
      $isActive &&
      css`
        padding-bottom: clamp(0.7rem, 1.0417rem + 2.6667vw, 0.2rem);
      `}
  }

  &:nth-child(3) {
    padding-bottom: clamp(1.3rem, 1.0417rem + 2.6667vw, 1.8rem);
  }

  ${mediumScreen(820)} {
    padding-left: 0;
    border-left: none;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      padding: 0;
    }
  }

  .question {
    display: flex;
    gap: 2.1rem;
    align-items: center;
    cursor: pointer;

    ${mediumScreen(breakPoint.sm)} {
      gap: 0;
      justify-content: space-between;
    }

    p {
      ${({ theme }) => theme.mixins.text_dark_light};

      line-height: 100%;
      font-size: clamp(1.1rem, 0.0139rem + 3.5556vw, 1.7rem);
      font-weight: 400;

      ${({ $isActive }) =>
        $isActive &&
        css`
          font-size: clamp(1.2rem, 0rem + 4vw, 2rem);
          font-weight: 600;
          color: var(--black);
        `}
    }

    button {
      all: unset;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg.up {
        color: var(--black);
        font-size: clamp(1.0248125rem, 0.1358rem + 2.8448vw, 2.1rem);
      }

      svg.down {
        ${({ theme }) => theme.mixins.text_dark_light};
        font-size: clamp(1rem, 0.5486rem + 1.4444vw, 1.4125rem);
      }
    }
  }
  div.answer {
    animation: ${slideDown} 0.5s ease-in-out forwards;
    display: none;

    &.active {
      display: block;
    }

    p {
      ${({ theme }) => theme.mixins.text_dark_light};
      font-size: clamp(0.875rem, 0.0417rem + 2.6667vw, 1.3rem);
      line-height: 120%;
      margin-top: clamp(0.5625rem, 0.3542rem + 0.6667vw, 0.9375rem);
    }

    .link {
      margin-top: 1.1875rem;

      a {
        font-size: clamp(0.75rem, 0.3333rem + 1.3333vw, 1.5rem);
        font-weight: 500;
        line-height: 120%;

        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        span {
          ${({ theme }) => theme.mixins.text_primary_light}
        }

        svg {
          color: var(--primary);
          font-size: clamp(0.9361875rem, 0.4841rem + 1.4468vw, 1.75rem);
        }
      }
    }

    .faq--img {
      margin-top: 2rem;
      display: none;

      ${mediumScreen(820)} {
        display: block;
      }
    }
  }
`;

export default Card;
