import {
  Hero,
  WhoWeAre,
  Technique,
  PricingModel,
  Faqs,
  Footer,
} from "components";

const Home = () => {
  return (
    <>
      <Hero />
      <Faqs />
      <WhoWeAre />
      <Technique />
      {/* <PricingModel /> */}
      <Footer />
    </>
  );
};

export default Home;
