import styled from "styled-components";

const Section = styled.section`
  padding-top: clamp(4rem, -1.0556rem + 9.7778vw, 7.5rem);
  .hero--text {
    ${({ theme }) => theme.mixins.text_45};

    p {
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
      font-weight: 600;
    }

    p:first-child {
      ${({ theme }) => theme.fonts.architect}
      font-weight: 400;
    }

    p:nth-child(2) {
      span {
        ${({ theme }) => theme.fonts.architect}
        color: var(--primary);
      }
    }
  }

  .hero--image {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: clamp(1rem, 0.1667rem + 2.6667vw, 2.5rem);
    position: relative;

    img {
      width: 100%;
      margin-bottom: -2px;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(105, 11, 225, 0.2);
      filter: blur(108.6px);
    }
  }
`;

export default Section;
