import styled from "styled-components";
// import { largeScreen } from "helpers";
// import { breakPoint } from "base";

const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1800px;
`;

//  ${largeScreen(breakPoint.lg)} {
//     width: 85%;
//   }
export default Container;
