import { useState } from "react";
import { Container, FaqCard } from "ui";
import { faqs } from "constants";
import Section from "./styles";
import mixpanel from 'mixpanel-browser';

const Faqs = () => {
  const [clickId, setClickId] = useState("q1");

  const handleClick = (id) => {
    mixpanel.track('features expanded', {
      id: id,
      feature: clickId,
      time: new Date().toLocaleString()
    })
    setClickId((prev) => (
      prev === id ? prev : id
      ));
  };

  const singleFaq = faqs.find((faq) => faq.id === clickId);

  const handleGetStarted = () => {
    mixpanel.track('get started on features', {
      feature: clickId,
      time: new Date().toLocaleString()
    })
    // Open Google Form in a new tab
    window.open("https://docs.google.com/forms/d/e/1FAIpQLScEZEAD8InTT8_-xcgEKPbLqvIgpo1DlJXUkHa4KyA8O_xxRw/viewform?embedded=true", "_blank");
  };

  return (
    <Section>
      <Container>
        <div className="faq--header">
          <h2
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            Find your voice
          </h2>
          <p
            data-aos="fade-right"
            data-aos-duration="1400"
            data-aos-once="true"
          >
            safest place to practice public speaking
          </p>
        </div>

        <div className="faq--container">
          <div
            className="faqs"
            data-aos="zoom-in-right"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            {faqs.map((faq) => (
              <FaqCard
                key={faq.id}
                faq={faq}
                onToggle={() => handleClick(faq.id)}
                isActive={clickId === faq.id}
                onGetStarted={handleGetStarted} // Pass the function to handle "Get started" click

              />
            ))}
          </div>
          <div
            className="image"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <img src={singleFaq?.image} alt="faq_img" loading="lazy" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Faqs;
