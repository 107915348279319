import { linksOne, linksTwo } from "constants";
import { Container, FooterLink } from "ui";

import FooterWrapper from "./styles";
const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <div className="footer--content">
          <p>
           Nerveflex lets you practice public speaking at your own pace, privately & risk free.
          </p>

          <FooterLink links={linksOne} />
          <FooterLink links={linksTwo} />
        </div>

        <p className="rights">© Nerveflex. All rights reserved.</p>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
