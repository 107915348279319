import { mediumScreen } from "helpers";
import { breakPoint } from "base";
import styled from "styled-components";

const Section = styled.section`
  padding-bottom: clamp(4.7rem, -0.3125rem + 12vw, 5.8rem);
  background-color: #fcfcfc;

  .about--box {
    display: flex;
    align-items: center;
    gap: clamp(2.4rem, 0.6667rem + 2.6667vw, 3rem);

    ${mediumScreen(breakPoint.sm)} {
      flex-direction: column-reverse;
    }

    div:first-child {
      flex: 0.9;
    }

    .about--info {
      flex: 1;
      align-self: center;

      ${mediumScreen(breakPoint.sm)} {
        align-self: flex-start;
      }

      h2 {
        font-size: clamp(1.5rem, 0.0139rem + 3.5556vw, 2.5rem);
        line-height: 120%;

        font-weight: 600;
      }

      p {
        font-size: clamp(0.9rem, 0.1636rem + 1.4764vw, 1.27rem);
        line-height: 120%;
        ${({ theme }) => theme.mixins.text_dark_light}

        line-height: 120%;
      }

      p.text--1 {
        margin-top: clamp(0.5rem, 1rem + 0vw, 0.8rem);

        span {
          color: var(--primary);
          font-weight: 700;
        }
      }

      P.text--2 {
        margin-top: clamp(1rem, 0.4444rem + 1.7778vw, 2rem);
      }

      a {
        font-size: clamp(0.75rem, 0.3333rem + 1.3333vw, 1.2rem);
        font-weight: 500;
        line-height: 120%;
        margin-top: clamp(0.6rem, 0.9306rem + 0.2222vw, 1.125rem);
        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        span {
          ${({ theme }) => theme.mixins.text_primary_light}
        }

        svg {
          color: var(--primary);
          font-size: clamp(0.9361875rem, 0.4841rem + 1.4468vw, 1.75rem);
        }
      }
    }
  }
`;

export default Section;
