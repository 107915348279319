import styled from "styled-components";
import { mediumScreen } from "helpers";
import { breakPoint } from "base";

const Section = styled.section`
  padding: clamp(3.4375rem, -0.3125rem + 12vw, 6.1875rem) 0;
  background: #fcfcfc;

  .technique--box {
    display: flex;
    flex-direction: column;
    gap: clamp(3.5625rem, -1.0903rem + 14.8889vw, 5.9375rem);

    ${mediumScreen(breakPoint.sm)} {
      flex-direction: column-reverse;
    }
  }

  a {
        font-size: clamp(0.75rem, 0.3333rem + 1.3333vw, 1.2rem);
        font-weight: 500;
        line-height: 120%;
        margin-top: clamp(0.6rem, 0.9306rem + 0.2222vw, 1.125rem);
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center; /* Add this line to center horizontally */
        column-gap: 0.5rem;

        span {
          ${({ theme }) => theme.mixins.text_primary_light}
        }

        svg {
          color: var(--primary);
          font-size: clamp(0.9361875rem, 0.4841rem + 1.4468vw, 1.75rem);
        }
      }

  .info {
    display: flex;
    align-items: center;
    gap: clamp(1.25rem, -10.2083rem + 36.6667vw, 7.875rem);

    ${mediumScreen(breakPoint.sm)} {
      flex-direction: column;
    }

    h2,
    p {
      ${mediumScreen(breakPoint.sm)} {
        text-align: center;
      }
    }

    h2 {
      ${({ theme }) => theme.mixins.text_50}
      flex: 1;
      font-weight: 600;
    }

    p {
      ${({ theme }) => theme.mixins.text_23};
      ${({ theme }) => theme.mixins.text_dark_light};

      line-height: 120%;
      flex: 1;
    }
  }

  img {
    flex: 1;
  }
`;

export default Section;
