import { Container } from "ui";
import { Speech } from "assets/images";
import Section from "./styles";
import { FiArrowUpRight } from "react-icons/fi";
import mixpanel from 'mixpanel-browser';


const Technique = () => {
  const googleFormLink = "https://docs.google.com/forms/d/e/1FAIpQLScEZEAD8InTT8_-xcgEKPbLqvIgpo1DlJXUkHa4KyA8O_xxRw/viewform?embedded=true";
  
  const trackGetStartedClick = () => {
    // Track the 'Get Started' button click event with Mixpanel
    mixpanel.track('how do we create workout?');
  };

  return (
    <Section>
      <Container>
        <div className="technique--box">
          <div className="info">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              How do we curate speaking work-outs?
            </h2>
            <p data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
              All work-outs are developed by popular speakers 
              and are helping people be more themselves while speaking in public.

              {/* 50 all time best speakers like Steve jobs, Mandela, Obama, Sadh
              guru, Gary vee etc were analyzed for their techniques. Also we
              joined a list of top public speaking advice from some of these
              speakers. */}
              
              <a
              href={googleFormLink}
              target="_blank"
              className="flex"
              data-aos="fade-up"
              data-aos-duration="1600"
              data-aos-once="true"
              onClick={trackGetStartedClick}
            >
              <span>Get started</span>
              <FiArrowUpRight />
            </a>
            
            </p>

          </div>

          <img
            src={Speech}
            alt="man_speaking"
            loading="lazy"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-once="true"
          />
        </div>
      </Container>
    </Section>
  );
};

export default Technique;
