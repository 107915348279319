import styled from "styled-components";
import { mediumScreen } from "helpers";
import { breakPoint } from "base";

const Section = styled.section`
  padding: clamp(8rem, -2.9583rem + 17.6667vw, 8.5rem) 0;
  background-color: #fcfcfc;

  .faq--header {
    text-align: center;

    h2 {
      ${({ theme }) => theme.mixins.text_50}
      font-weight: 600;
    }

    p {
      ${({ theme }) => theme.fonts.architect};
      ${({ theme }) => theme.mixins.text_23};
      ${({ theme }) => theme.mixins.text_dark_light};

      line-height: 120%;

      ${mediumScreen(breakPoint.sm)} {
        width: 200px;
        margin: auto;
        max-width: 100%;
      }
    }
  }
  .faq--container {
    margin-top: clamp(2.5rem, 0.0625rem + 12vw, 4rem);
    display: flex;
    align-items: center;

    gap: clamp(2.3rem, 2.7778rem + 2.1111vw, 2.5rem);

    ${mediumScreen(820)} {
      flex-direction: column;

      .image {
        display: none;
      }
    }

    .faqs {
      flex: 1;

      ${mediumScreen(820)} {
        display: grid;
        gap: 2rem;
      }
    }

    .image {
      flex: 1;
    }
  }
`;

export default Section;
