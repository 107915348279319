// import React from "react";
import { Container } from "ui";
import Header from "./styles";
import { Link } from "react-router-dom";
import mixpanel from 'mixpanel-browser';


const Navbar = () => {
  const googleFormLink = "https://docs.google.com/forms/d/e/1FAIpQLScEZEAD8InTT8_-xcgEKPbLqvIgpo1DlJXUkHa4KyA8O_xxRw/viewform?embedded=true";
  const trackGetStartedClick = () => {
    // Track the 'Get Started' button click event with Mixpanel
    mixpanel.track('navbar signup');
  };

  return (
    <Header>
      <Container>
        <nav className="flex">
          <h6>Nerveflex</h6>

          <ul className="flex">
            <li>
            {/* <Link className="link" to="/">
                About
              </Link> */}
              {/* <a className="link" href="/">
                About
              </a> */}
            </li> 
            {/* <li>
              <a href={googleFormLink} target="_blank">
                Contact
              </a>
            </li> */}
            <li>
            <a href={googleFormLink}
            onClick={trackGetStartedClick} target="_blank">
                Sign Up
              </a>
            {/* <Link to="#">Sign Up</Link> */}
              
            </li>
          </ul>
        </nav>
      </Container>
    </Header>
  );
};

export default Navbar;
