import { AppRoutes } from "constants";
import { ThemeProvider } from "styled-components";
import useAnimate from "hooks/useAnimate";
import { Routes, Route } from "react-router-dom";
import { GlobalStyles, theme } from "base";
import { Home } from "pages";
import { AppLayout } from "layout";
import "aos/dist/aos.css";
import mixpanel from 'mixpanel-browser';

// grab the Mixpanel factory
// var Mixpanel = require('mixpanel');
 
// create an instance of the mixpanel client
mixpanel.init("0815f9f8546508f807fb0edd9a259c6e", {track_pageview: true});

function App() {
  useAnimate();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes>
        <Route path={AppRoutes.home} element={<AppLayout />}>
          <Route index={true} element={<Home />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
