import { Run, Find, Explore } from "assets/images";

export const faqs = [
  {
    id: "q1",
    question: "Browse speaking work-outs",
    answer:
      // "Browse from 100+ public speaking exercises inspired by favourites like Obama, Trevor Noah, Sadhguru etc. and crafted to help you develop core public speaking skills.",
      "Explore 100+ public speaking work-outs designed by popular stand-up comedians to enhance every aspect of your public speaking.",
      link: "Get started",
    image: Explore,
  },
  {
    id: "q2",
    question: "Perform speaking work-outs",
    answer:
      "Perform public speaking work-outs in front of human audience to see how much it helps you or if you'd like more practice on it.",
    link: "Get started  ",
    image: Run,
  },
  {
    id: "q3",
    question: "Curate favourite work-outs",
    answer:
      "Need more practise? Your favourite speaking work-outs are curated for you to practise till you perfect them.",
    link: "Get started",
    image: Find,
  },
];
