import { css } from "styled-components";

const mixins = {
  text_16: css`
    font-size: 1rem;
  `,
  text_20: css`
    font-size: clamp(0.7rem, 0.059rem + 1.4112vw, 1.2938125rem);
    line-height: 137.5%;
  `,

  text_21: css`
    font-size: clamp(0.875rem, 0.6181rem + 0.8222vw, 1.3375rem);
    line-height: 171.429%;
  `,

  text_22: css`
    font-size: clamp(1rem, 0.2083rem + 1.3333vw, 1.375rem);
    line-height: 117.617%;
  `,
  text_23: css`
    font-size: clamp(0.9rem, 0.1636rem + 1.4764vw, 1.4555rem);
    line-height: 111.111%;
  `,
  text_25: css`
    font-size: clamp(0.875rem, 0.4931rem + 1.2222vw, 1.5625rem);
    line-height: 100%;
  `,
  text_28: css`
    font-size: clamp(1.1875rem, -0.0248rem + 2.0794vw, 1.7946875rem);
    line-height: normal;
  `,
  text_30: css`
    font-size: clamp(0.5525625rem, -0.1902rem + 2.3769vw, 1.8895625rem);
    line-height: normal;
  `,
  text_31: css`
    font-size: clamp(0.75rem, 0.0885rem + 2.1168vw, 1.9406875rem);
    line-height: 145.833%;
  `,

  text_32: css`
    font-size: clamp(0.75rem, 0.0436rem + 2.2606vw, 2.0215625rem);
    line-height: 255%;
  `,

  text_35: css`
    font-size: clamp(1.209875rem, 0.6668rem + 1.738vw, 2.1875rem);
    line-height: 100%;
  `,
  text_42: css`
    font-size: clamp(0.75rem, -0.3244rem + 3.4382vw, 2.684rem);
    line-height: 120%;
  `,
  text_45: css`
    font-size: clamp(1.38rem, -0.0799rem + 3.3056vw, 2.8125rem);
    line-height: 120%;
  `,
  text_50: css`
    font-size: clamp(1.5rem, 0.0139rem + 3.5556vw, 3.125rem);
    line-height: 120%;
  `,
  text_62: css`
    font-size: clamp(1.201125rem, -0.3185rem + 4.8629vw, 3.9365rem);
    line-height: 100%;
  `,
  text_64: css`
    font-size: clamp(1.125rem, -0.4962rem + 5.1878vw, 4.043125rem);
    line-height: 132%;
  `,
  text_86: css`
    font-size: clamp(1.3571875rem, -0.8781rem + 7.1529vw, 5.3806875rem);
    line-height: 126.731%;
  `,
  text_90: css`
    font-size: clamp(1.905625rem, -0.1607rem + 6.6122vw, 5.625rem);
    line-height: 120%;
  `,
  text_light: css`
    color: #fff;
    opacity: 0.5;
  `,
  text_dark_light: css`
    color: rgba(0, 0, 0, 0.5);
  `,

  text_primary_light: css`
    color: #690be1;
    opacity: 0.7;
  `,
};

const fonts = {
  architect: css`
    font-family: "Architects Daughter", cursive;
  `,
  lexend: css`
    font-family: "Lexend", sans-serif;
  `,
};

export const breakPoint = {
  sm: 600,
};

export const theme = {
  mixins,
  fonts,
};
