import { Container } from "ui";
import { Meeting } from "assets/images";
import { FiArrowUpRight } from "react-icons/fi";
import Section from "./styles";
import { Link } from "react-router-dom";
import mixpanel from 'mixpanel-browser';

const WhoWeAre = () => {
  const googleFormLink = "https://docs.google.com/forms/d/e/1FAIpQLScEZEAD8InTT8_-xcgEKPbLqvIgpo1DlJXUkHa4KyA8O_xxRw/viewform?embedded=true";

  const trackGetStartedClick = () => {
    // Track the 'Get Started' button click event with Mixpanel
    mixpanel.track('who is nerveflex for?');
  };

  return (
    <Section>
      <Container>
        <div className="about--box">
          <div
            data-aos="zoom-in-down"
            data-aos-duration="1300"
            data-aos-once="true"
          >
            <img src={Meeting} alt="meeting" loading="lazy" />
          </div>

          <div className="about--info">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              Who is Nerveflex for?
            </h2>
            <p
              className="text--1"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              Do you feel like you'll benefit from practising public speaking in front of a <span>safe</span> audience? </p>            

            <p
              className="text--2"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="true"
            >
              We feel this way too and have built nerveflex for people like us.
            </p> 

            {/* <Link
              to={"https://docs.google.com/forms/d/e/1FAIpQLScEZEAD8InTT8_-xcgEKPbLqvIgpo1DlJXUkHa4KyA8O_xxRw/viewform?embedded=true"}
              href={googleFormLink}
              className="flex"
              data-aos="fade-up"
              data-aos-duration="1600"
              data-aos-once="true"
            >
              <span>Get started</span>
              <FiArrowUpRight />
            </Link> */}

            <a
              href={googleFormLink}
              target="_blank"
              className="flex"
              data-aos="fade-up"
              data-aos-duration="1600"
              data-aos-once="true"
              onClick={trackGetStartedClick}
            >
              <span>Get started</span>
              <FiArrowUpRight />
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default WhoWeAre;
