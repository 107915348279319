import styled, { css } from "styled-components";
import { largeScreen, mediumScreen } from "helpers";
import { breakPoint } from "base";

export const PriceItem = styled.div`
  border-radius: 16px;
  border: 1.294px solid #eff0f6;
  background: #fff;
  box-shadow: 0px 2.58758px 15.5255px 0px rgba(20, 20, 43, 0.08);

  &:first-child {
    height: fit-content;
  }

  div.item--container {
    transition: background 0.4s ease-in;
    cursor: pointer;
    padding: 0 clamp(0.625rem, -0.0694rem + 2.2222vw, 1.875rem) 1rem;
    border-radius: 0 0 16px 16px;

    ${({ $type }) =>
      $type === "Gold" &&
      css`
        background-color: var(--primary);

        button {
          background-color: var(--white) !important;
          color: var(--primary) !important;
        }

        p {
          color: var(--white) !important;
        }

        li {
          color: var(--white) !important;
        }

        p.billed {
          opacity: 1;
        }

        p.individuals {
          opacity: 1 !important;
        }

        span.check {
          background-color: var(--white) !important;

          svg {
            color: var(--primary) !important;
          }
        }
      `}

    &:hover {
      background-color: var(--primary);

      button {
        background-color: var(--white) !important;
        color: var(--primary) !important;
      }

      p {
        color: var(--white) !important;
      }

      li {
        color: var(--white) !important;
      }

      p.billed {
        opacity: 1;
      }

      p.individuals {
        opacity: 1 !important;
      }

      span.check {
        background-color: var(--white);

        svg {
          color: var(--primary) !important;
        }
      }
    }
  }

  h6 {
    ${({ theme }) => theme.mixins.text_21}

    background-color: var(--silver);
    text-transform: uppercase;
    color: var(--white);
    font-size: clamp(0.525rem, 0.2083rem + 1.3333vw, 0.7rem);
    padding: clamp(0.3375rem, 0.3333rem + 0.3333vw, 0.4rem) 0;
    text-align: center;
    border-radius: 16px 16px 0 0;
  }

  .price--info {
    margin: 0 auto;
    text-align: center;

    ${mediumScreen(breakPoint.sm)} {
      margin: 0;
      text-align: left;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon--box {
        display: flex;
        align-items: center;
        column-gap: 1rem;
      }
    }

    .icon--box {
      svg {
        width: clamp(3.375rem, 0.4653rem + 6.1111vw, 3.2rem);
      }

      & > div p:nth-child(1) {
        font-size: clamp(0.9rem, 0.4931rem + 1.2222vw, 1.2rem);

        font-weight: 600;
        line-height: 145.833%;
        color: var(--neutral);
      }

      & > div p:nth-child(2) {
        font-size: clamp(0.725rem, 0.1736rem + 1.4444vw, 0.96rem);
        line-height: 111.111%;
        color: var(--primary-grey);
        opacity: 0.7;

        ${mediumScreen(breakPoint.sm)} {
          margin-top: 0.2rem;
        }
      }
    }

    .price {
      margin-top: clamp(1.375rem, 1.0278rem + 1.1111vw, 2rem);

      p.slashed {
        font-size: clamp(1.1rem, 0.0556rem + 2.2222vw, 1.2rem);
        color: var(--primary-grey);
        font-weight: 500;
        opacity: 0.6;
        text-decoration: line-through;
      }

      ${mediumScreen(breakPoint.sm)} {
        margin-top: 0;
        display: grid;
        gap: 0.3rem;
        text-align: right;

        p.slashed--small {
          display: block;
        }
        p.slashed--big {
          display: none;
        }
      }

      ${largeScreen(breakPoint.sm)} {
        p.slashed--small {
          display: none;
        }
      }

      p.price--tag {
        color: var(--neutral);
        font-size: clamp(1.5rem, -0.4722rem + 5.1111vw, 2.1rem);
        font-weight: 600;
      }

      p.billed {
        font-size: clamp(0.725rem, 0.1736rem + 1.4444vw, 0.96rem);
        line-height: 111.111%;
        color: var(--primary-grey);
        opacity: 0.7;
      }
    }
  }

  .benefits {
    ${mediumScreen(breakPoint.sm)} {
      p {
        display: none;
      }
    }
    p {
      color: var(--neutral);
      font-feature-settings: "clig" off, "liga" off;
      font-weight: 600;
      line-height: 111.111%;
      font-size: clamp(0.725rem, 0.1736rem + 1.4444vw, 0.9rem);
      margin-top: clamp(1.125rem, 0.9514rem + 0.5556vw, 1.4375rem);
    }

    ul {
      margin-top: clamp(0.75rem, 0.5417rem + 0.6667vw, 1.125rem);
      display: grid;
      gap: 0.9rem;

      li {
        font-size: clamp(0.725rem, 0.1736rem + 1.4444vw, 0.9rem);
        color: var(--neutral);
        display: flex;
        align-items: center;
        column-gap: 0.6rem;

        span.check {
          padding: clamp(0.2rem, 0.9444rem + -0.2222vw, 0.2rem);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--primary);
          border-radius: 100%;

          svg {
            color: var(--white);
          }
        }
      }
    }
  }

  button {
    all: unset;
    text-align: center;
    transition: background 0.4s ease-in;
    color: var(--white);
    padding: clamp(0rem, 0.2569rem + 5.7778vw, 0.8rem) 0;
    background-color: var(--primary);
    font-weight: 600;
    border-radius: 99.968px;
    margin-top: clamp(1.25rem, 0.5556rem + 2.2222vw, 1.5rem);
    font-size: clamp(0.875rem, 0.6667rem + 0.6667vw, 1rem);
    cursor: pointer;
    width: 100%;
  }
`;

const Section = styled.section`
  padding: clamp(3.4375rem, -0.3125rem + 12vw, 6.1875rem) 0;

  .price--header {
    text-align: center;

    h2 {
      ${({ theme }) => theme.mixins.text_50}
      font-weight: 600;
    }

    p {
      ${({ theme }) => theme.fonts.architect};
      ${({ theme }) => theme.mixins.text_23};
      ${({ theme }) => theme.mixins.text_dark_light};

      line-height: 120%;
      margin-top: clamp(0.2rem, 0.9306rem + 0.2222vw, 0.4rem);
    }
  }

  .price--box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(278px, 100%), 1fr));
    gap: clamp(1.25rem, 0.5556rem + 2.2222vw, 2rem);
    width: 966px;
    max-width: 100%;
    margin: clamp(3.6125rem, 0.0625rem + 12vw, 5.5625rem) auto 0;
  }
`;

export default Section;
